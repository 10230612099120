import React from "react";
import { Slide, Fade } from "react-reveal";
export default function Resume() {
	return (
		<>
			<section id="resume">
				<div class="row work">
					<div class="three columns header-col">
						<h1>
							<span>Work Experience</span>
						</h1>
					</div>
					<Fade right cascade>
						<div class="nine columns main-col">
							<div class="row item">
								<div class="twelve columns">
									<h3>JP Morgan Chase</h3>
									<p class="info">
										Software Engineer
										<span>&bull;</span>
										<em class="date">August 2024 - Present</em>
									</p>
									<p>
										Full Stack Software Engineer in the AWM Risk team. I leverage React and Java
										Spring Boot
									</p>
								</div>
							</div>

							<div class="row item">
								<div class="twelve columns">
									<h3>Express Scripts</h3>
									<p class="info">
										Software Engineering Senior Analyst
										<span>&bull;</span>
										<em class="date">June 2021 - August 2024</em>
									</p>
									<p>
										Develop, maintain and improve several Microservices and UIs with Java Spring
										Boot and ReactJS under the Benefit Testing Tool team. Lead migration of
										Microservices Deployment from Pivotal Cloud Foundry to OpenShift. Utilize Git
										and Jenkins for CI/CD. Work in an Agile environment.
									</p>
								</div>
							</div>

							<div class="row item">
								<div class="twelve columns">
									<h3>General Motors</h3>
									<p class="info">
										Software Developer <span>&bull;</span>{" "}
										<em class="date">January 2021 - June 2021</em>
									</p>
									<p>
										Effectively built and maintained solutions independently and collaboratively in
										the Customer Care &amp; Aftersales department. Developed Python applications to
										enhance current NLP based products. Worked in Agile environment. Interacted and
										gave live demos of products to product owners and customers to gather feedback
										and technical requirements.
									</p>
								</div>
							</div>
						</div>
					</Fade>
					<div class="three columns header-col">
						<h1>
							<span>Volunteer</span>
						</h1>
					</div>
					<Fade right cascade>
						<div class="nine columns main-col">
							<div class="row item">
								<div class="twelve columns">
									<h3>Armenian Home of NY</h3>
									<p class="info">
										Tech Volunteer<span>&bull;</span> <em class="date">August 2019 - Present</em>
									</p>

									<p>
										Lead efforts to redesign and implement website (
										<a href="http://www.armenianhomeny.org">www.armenianhomeny.org</a>) of a nursing
										home organization. Provide monthly progress reports to AHNY leadership while
										incorporating their feedback and new ideas
									</p>
								</div>
							</div>
						</div>
					</Fade>
				</div>

				<div class="row education">
					<div class="three columns header-col">
						<h1>
							<span>Education</span>
						</h1>
					</div>

					<Fade right cascade>
						<div class="nine columns main-col">
							<div class="row item">
								<div class="twelve columns">
									<h3>Syracuse University</h3>
									<p class="info">
										B.S. in Systems &amp; Information Science
										<span>&bull;</span> <em class="date">December 2019</em>
									</p>

									<p>
										Specialized in Web Development and Data Analysis.
										<br />
										Minor: Engineering &amp; Computer Science Management
									</p>
								</div>
							</div>
						</div>
					</Fade>
				</div>

				<div class="row skill">
					<div class="three columns header-col">
						<h1>
							<span>Skills</span>
						</h1>
					</div>

					<div class="nine columns main-col">
						<p>
							I have a wide variety of skills, I specialize in Web Development and Data Analysis.
							Not all of my skills are listed here. All of them can be found on my resume!
						</p>

						<div class="bars">
							<ul class="skills">
								<Slide left>
									<li>
										<span class="bar-expand javascript"></span>
										<em>JavaScript / Typescript</em>
									</li>
								</Slide>
								<Slide right>
									<li>
										<span class="bar-expand reactjs"></span>
										<em>ReactJS</em>
									</li>
								</Slide>
								<Slide left>
									<li>
										<span class="bar-expand nextJS"></span>
										<em>NextJS</em>
									</li>
								</Slide>
								<Slide right>
									<li>
										<span class="bar-expand java"></span>
										<em>Java</em>
									</li>
								</Slide>
								<Slide left>
									<li>
										<span class="bar-expand python"></span>
										<em>Python</em>
									</li>
								</Slide>
								<Slide right>
									<li>
										<span class="bar-expand coworker"></span>
										<em>Being a fun, collaborative, and impactful coworker!</em>
									</li>
								</Slide>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
